module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"COVID-19 interactome","short_name":"COVID-19 interactome","start_url":"/","background_color":"#7dc383","theme_color":"#7dc383","display":"standalone","icon":"src/images/virus.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"787d08b549451c28228a5146818cf5cb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"customDomain":"analytics.jamesknight.dev","domain":"covid19interactome.org"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
