// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-downloads-index-js": () => import("./../../../src/pages/downloads/index.js" /* webpackChunkName: "component---src-pages-downloads-index-js" */),
  "component---src-pages-heatmap-index-js": () => import("./../../../src/pages/heatmap/index.js" /* webpackChunkName: "component---src-pages-heatmap-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-templates-bait-template-js": () => import("./../../../src/templates/bait-template.js" /* webpackChunkName: "component---src-templates-bait-template-js" */)
}

